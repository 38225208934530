<template>
  <div>
    <v-layout
      class="c-loginPage"
      justify-center
      column
      align-center
    >
      <v-card
        style="width:80%; max-width: 800px;"
        class="mb-4 text-center">
        <v-card-text>
          <h1>MeinBackend</h1>
          <img
            src="../../../public/img/meinhilfsmittel.svg"
            class="c-loginPage__logo mx-2"
            alt="meinhilfsmittel" />
          <img
            src="../../../public/img/meinelektromobil.svg"
            class="c-loginPage__logo mx-2"
            alt="meinelektromobil" />
        </v-card-text>
      </v-card>
      <v-alert
        v-if="showWelcome"
        class="mb-8"
        style="width:80%; max-width: 800px;"
        colored-border
        type="info"
        border="left"
        elevation="2">
        <h3 style="margin-top: -5px">
          Willkommen im neuen Backend
        </h3>
        Bitte loggen Sie sich mit Ihren MeinHilfsmittel Zugangsdaten ein.<br>
        Wenn Sie Fragen zum neuen Backend haben, kontaktieren Sie uns gern unter <a href="mailto:support@meinhilfsmittel.de">support@meinhilfsmittel.de</a> oder <span class="font-weight-bold">05136 9759-408</span>.
      </v-alert>
      <v-card
        class="mb-8"
        style="width:80%; max-width: 800px;">
        <v-card-text>
          <v-form
            v-model="isValid"
            class="mt-4"
            @submit.prevent="loginProcess">
            <v-text-field
              label="E-mail"
              outlined
              v-model="email"
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              label="Passwort"
              outlined
              v-model="password"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />
            <v-btn
              block
              :loading="loading"
              :disabled="!isValid"
              type="submit"
              color="primary"
              class="mb-6">
              Anmelden
            </v-btn>
            <v-divider class="my-3"/>
            <p class="text-center">
              © Ludwig Bertram GmbH | <a
                href="https://meinhilfsmittel.de/impressum"
                target="_blank">Impressum</a>
            </p>
          </v-form>
        </v-card-text>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      isValid: false,
      showPassword: false,
      email: null,
      password: null,
      rules: {
        required: (value) => !!value || 'Erforderlich',
        email: (value) => (value && value.includes('@')) || 'Ungültige E-Mail',
      },
      showWelcome: false,
    }
  },
  created() {
    if (this.$route.query.fromOldBackend) {
      this.showWelcome = true
      this.$router.replace({ name: 'login' })
    }
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),
  },
  methods: {
    ...mapActions('auth', ['login']),
    loginProcess() {
      if (!this.isValid) {
        return
      }
      this.login({ email: this.email, password: this.password }).then(() => {
        this.$router.replace('/dashboard')
      }).catch((e) => {
        /* eslint-disable-next-line no-console */
        console.error(e)
        this.$toasted.error('Bitte überprüfen Sie Ihre Zugangsdaten.')
      })
    },
  },
}
</script>
<style>
#app h1 {
    font-size: 2.857rem !important;
}

.c-loginPage {
    min-height: 100vh;
    background: url('../../../public/img/bg-login.jpg') center no-repeat;
    background-size: cover;
}

.c-loginPage__logo{
    height:50px;
}

#core-view {
    padding-bottom: 0;
}
</style>
